import React from "react";
import { connect, useSelector } from "react-redux";
import { GlossaryQuery } from "../../prismic/staticQueries";

import Layout from "../../layout";
import { ContentBasic } from "../../components/common";
import BANNER from "../../images/siteImages/glossary/GLOSSARY_BANNER.jpg";

const Forex = () => {
  const language = useSelector((state) => state.language);
  const glossaryData = GlossaryQuery(language);
  return (
    <Layout>
      {/* <ContentWithTable multitable={false} {...glossaryData}  show_stats={false} /> */}
      <ContentBasic
        {...glossaryData}
        banner_image={BANNER}
        show_stats={false}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(Forex);
